<section class="main-content">
  <section class="header-section">
    <section class="title-section">
      <h1>Batch exports</h1>
    </section>
    <section class="actions-section">
      <section class="filter-section">
        <app-report-filter-export
          [title]="'Filter by status'"
          [filterValues]="filterValues"
          [currentValue]="currentStatus"
          (filterByEvent)="filterByStatus($event)">
        </app-report-filter-export>
      </section>
      <section class="buttons-section">
        <button mat-mini-fab
                color="accent"
                data-type="button-refresh"
                (click)="refresh()"
                matTooltip="Refresh list">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>
  </section>
  <div class="card-container">
    <mat-card class="no-padding">
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section class="table-section">
      <table mat-table [dataSource]="dataSource" [hidden]="loading$ | async">
        <!-- timestamp -->
        <ng-container matColumnDef="timestamp">
          <mat-header-cell class="lg-cell" *matHeaderCellDef>
            <span>Export date</span>
          </mat-header-cell>
          <mat-cell class="lg-cell" *matCellDef="let element"><span data-type="job-export-date"> {{element.timestamp | date:'medium'}} </span>
          </mat-cell>
        </ng-container>

        <!-- label names -->
        <ng-container matColumnDef="ruleIds">
          <mat-header-cell *matHeaderCellDef><span>Rules</span></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span data-type="job-rules">
              {{displayLabelList(element)}}
              <span *ngIf="element.automatic" title="automated rule"><i>(automated)</i></span>
            </span>
          </mat-cell>
        </ng-container>

        <!-- imported / total -->
        <ng-container matColumnDef="exported">
          <mat-header-cell class="md-cell" *matHeaderCellDef>
            <span>Nb emails</span>
          </mat-header-cell>
          <mat-cell class="md-cell"  *matCellDef="let element"><span data-type="job-nb-emails">{{element.total}}</span></mat-cell>
        </ng-container>

        <!-- errors -->
        <ng-container matColumnDef="errors">
          <mat-header-cell class="md-cell" *matHeaderCellDef>Errors</mat-header-cell>
          <mat-cell class="md-cell" *matCellDef="let element"><span data-type="job-nb-errors"> {{element.failed}}</span></mat-cell>
        </ng-container>

        <!-- status -->
        <ng-container matColumnDef="status">
          <mat-header-cell class="md-cell" *matHeaderCellDef><span>Status</span>
          </mat-header-cell>
          <mat-cell class="md-cell" *matCellDef="let element">
            <app-report-status-column
              [statusClass]="getJobStatusClass(element.status)"
              [userStatus]="element.userStatus">
            </app-report-status-column>
          </mat-cell>
        </ng-container>

        <!-- message -->
        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef>
            <span>Details</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"><span data-type="job-details"> {{showDetailsMessage(element)}}</span></mat-cell>
        </ng-container>

        <!-- view details -->
        <ng-container matColumnDef="view">
          <mat-header-cell class="sm-cell" *matHeaderCellDef>
            <span>Actions</span>
          </mat-header-cell>
          <mat-cell class="sm-cell" *matCellDef="let element">
            <button mat-icon-button class="table-button" data-type="button-view-detail" [attr.data-label]="element.id" (click)="viewDetails(element)"
                    matTooltip="View details">
              <mat-icon svgIcon="view_data"></mat-icon>
            </button>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" data-type="job-no-item">No item to display.</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator
        [length]="totalItems"
        [pageSizeOptions]="[pageSize]"
        [hidePageSize]="true"
        [showFirstLastButtons]="false"
        [pageIndex]="pageIndex"
        (page)="handlePageEvent($event)"></mat-paginator>
    </section>
  </mat-card>
  </div>
</section>
