import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../models/dialog-data.model';
import {DialogType} from '../../constants/dialog-types';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public title;
  public type: DialogType;
  public message;
  public description;
  public confirmButtonText;
  public cancelButtonText;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.title = data.title;
    this.message = data.message;
    this.description = data.description;
    this.type = data.type;
    this.confirmButtonText = data.confirmButtonText || 'OK';
    this.cancelButtonText = data.cancelButtonText || 'Cancel';
  }

  ngOnInit(): void {
  }

  isChoiceBased(): boolean {
    return this.type === DialogType.PLAIN;
  }

}
