<section class="about-dialog">

<header>
  <h1 mat-dialog-title>About</h1>
</header>
<div mat-dialog-content>
  <p>Version: {{version}}</p>
  <p>Copyright © 2013 Altirnao, Inc</p>
  <p>U.S. Patent 9,817,988</p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close data-type="button-dialog-ok">OK</button>
</div>
</section>
