<div class="actions-container">
  <a *ngIf="messageExportJob.documentLink"
     [href]="messageExportJob.documentLink"
     target="_blank"
     class="table-button"
     data-type="action-open-document"
     [matTooltip]="'Open in AODocs'">
    <mat-icon svgIcon="ao_open_in_new"></mat-icon>
  </a>
  <a *ngIf="retryEnable && messageExportJob.retryable"
     [matTooltip]="'Retry'"
     (click)="retry(messageExportJob.jobId)"
     class="table-button"
     data-type="action-retry">
    <mat-icon svgIcon="ao_replay"></mat-icon>
  </a>
</div>
