import {Component, Input, OnInit} from '@angular/core';
import {MessageExportJob, MessageStatus} from '../../../../../../models/rules-export-job';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogComponent} from '../../../../../../components/dialog/dialog.component';
import {DialogService} from '../../../../../../services/dialog.service';

@Component({
  selector: 'app-report-detail-column',
  templateUrl: './details-column.component.html',
  styleUrls: ['./details-column.component.scss'],
})
export class DetailsColumnComponent implements OnInit {

  constructor(private dialogService: DialogService) {
  }

  @Input() messageExportJob: MessageExportJob;
  @Input() isRuleExportDetail = false;
  message: string;
  inError = false;
  isTruncated = false;

  private errorMessagesPrefix = [
    {key: '[RATE_LIMIT_EXCEEDED]', message: 'Rate limit exceeded. Please try again later.'},
    {key: '[TOO_MANY_REQUEST]', message: 'Too many requests at the same time. Please try again later.'},
    {key: '[DATABASE_ACCESS_ERROR]', message: 'An error occurred while accessing the database. Please try again later.'},
    {key: '[NOT_ENOUGH_TIME]', message: 'The export took too long to complete. Please try again.'},
    {key: '[ENTITY_NOT_FOUND][OUTLOOK]', message: 'Email not found in Outlook. It has been probably deleted before the end of the export process.'},
    {key: '[ENTITY_NOT_FOUND][GMAIL]', message: 'Email not found in Gmail. It has been probably deleted before the end of the export process.'},
    {key: '[ACCESS_DENIED][AODOCS][LIBRARY]', message: 'You can\'t access this library.'},
    {key: '[FORBIDDEN][AODOCS][LIBRARY]', message: 'You can\'t access this library.'},
    {key: '[BAD_CONFIGURATION][AODOCS][DOCUMENT_CLASS]', message: 'Wrong configuration in the library or the document class in AODocs.'},
    {key: '[NOT_FOUND][AODOCS][LIBRARY]', message: 'Library not found in AODocs.'},
    {key: '[NOT_FOUND][AODOCS][DOCUMENT_CLASS]', message: 'Document class not found in AODocs.'},
    {key: '[UNREGISTERED][AODOCS][USER]', message: 'Problem with your user permissions in AODocs.'},
    {key: '[NOT_FOUND][AODOCS][USER]', message: 'The user was not found in any of AODocs registered domains.'},
    {key: '[NOT_FOUND]', message: 'Internal error during the export. A data was not found to process the export. Please try again later.'},
    {key: '[READING_CONTENT_ERROR]', message: 'Error reading the content of the email. Please try again later.'}
  ];

  ngOnInit(): void {
    this.inError = this.messageExportJob.status === MessageStatus.FAILED ||
      this.messageExportJob.status === MessageStatus.ERROR ||
      this.messageExportJob.status === MessageStatus.WARNING;

    if (this.messageExportJob.status === MessageStatus.ALREADY_EXPORTED) {
      this.message = 'An email with this ID already exists in the selected class.';
    } else if (this.inError) {
      const exportMessage = this.messageExportJob.exportMessage;
      this.message = this.getErrorMessage(exportMessage) || this.truncateMessage(exportMessage);
    }
  }

  openDetails(job: any): MatDialogRef<DialogComponent, boolean> {
    if (this.inError) {
      return this.dialogService.info(job.exportMessage, 'Details');
    }
  }

  private truncateMessage(message: string): string {
    if (message.length > 100) {
      this.isTruncated = true;
      return message.substring(0, 100) + '...';
    }
    return message;
  }

  private getErrorMessage(exportMessage: string): string {
    const error = this.errorMessagesPrefix.find(error => exportMessage?.startsWith(error.key));
    return error ? error.message : null;
  }
}
