import {Component} from '@angular/core';
import {AboutDialogComponent} from '../about-dialog/about-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../../../services/dialog.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {

  constructor(public dialog: MatDialog,
              public dialogService: DialogService) {
  }

  openAboutDialog(): void {
    this.dialog.open(AboutDialogComponent, {autoFocus: false, panelClass: 'about-dialog'});
  }
}
