import {Router, UrlTree} from '@angular/router';
import {filter, first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../user.service';
import {inject, Injectable} from '@angular/core';

/**
 * Common guard service for checking if a feature is available.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonFeatureGuard {

  constructor(private readonly router: Router, private readonly userService: UserService) {
  }

  canActivate(featureCheck: (features: any) => boolean): Observable<boolean | UrlTree> {
    return this.userService.featuresSubject.pipe(
      filter(activatedFeatures => !!activatedFeatures),
      first(),
      map(activatedFeatures => {
        if (featureCheck(activatedFeatures)) {
          return true;
        } else {
          return this.redirectToPersonalSettings();
        }
      })
    );
  }

  redirectToPersonalSettings(): UrlTree {
    return this.router.createUrlTree(['dashboard', 'personal-settings']);
  }
}
