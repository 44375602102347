import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-microsoft-auth',
  templateUrl: './microsoft-auth.component.html',
  styleUrls: ['./microsoft-auth.component.scss']
})
export class MicrosoftAuthComponent implements OnInit {


  ngOnInit(): void {
    const search = new URLSearchParams(window.document.location.search);
    const code = search.get('code');
    if (code) {
      window.opener.postMessage(JSON.stringify({ code }), window.opener.origin);
      window.close();
    }
  }
}
